import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ReactSVG } from "react-svg";

const MobileDrawer = ({ isOpen, onClose, children }) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 h-full w-full  z-40 lg:hidden"
          onClick={onClose}
        >
          <div className="bg-black bg-opacity-50 z-40 lg:hidden fixed top-[80px] md:top-0 left-0 h-full w-full"></div>
        </div>
      )}

      {/* Drawer */}
      <div
        className={`
        fixed bottom-0 left-0 h-[calc(100%-80px)] md:h-[calc(100%)] bg-white z-50 transform transition-transform duration-300 ease-in-out w-64
        lg:hidden
        ${isOpen ? "translate-x-0" : "-translate-x-full"}
      `}
      >
        {children}
      </div>
    </>
  );
};

const DashboardSidebar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const lastSegment = location.pathname.split("/").pop();

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Cleanup on unmount
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isDrawerOpen]);

  if (lastSegment === "contract") return null;

  const navConfig = [
    {
      title: "Equipment List",
      path: "/dashboard-installer/equipmentlist",
      icon: "/icons/home_repair_service.svg",
    },
    {
      title: "My Deals",
      path: "/dashboard-installer/Mydeals",
      icon: "/icons/handshake.svg",
    },
    {
      title: "My Portfolio",
      path: "/dashboard-installer/portfolio",
      icon: "/icons/burst_mode.svg",
    },
    {
      title: "Lead Purchase",
      path: "/dashboard-installer/lead-purchase",
      icon: "/icons/lead-icon.svg",
    },
  ];

  const SidebarContent = () => (
    <>
      <NavLink
        className={({ isActive }) =>
          `px-8 py-5 flex items-center gap-x-2 ${
            isActive && lastSegment === "dashboard-installer"
              ? "text-[#D0680F] font-semibold bg-[#f7f6f5]"
              : ""
          }`
        }
        to={"/dashboard-installer"}
        onClick={() => setIsDrawerOpen(false)}
      >
        {({ isActive }) => (
          <>
            <ReactSVG
              src="/icons/team_dashboard.svg"
              beforeInjection={(svg) => {
                svg.querySelectorAll("path").forEach((path) => {
                  path.setAttribute(
                    "fill",
                    isActive && lastSegment === "dashboard-installer"
                      ? "#D0680F"
                      : "#6B7280"
                  );
                });
              }}
              className="w-6 h-6"
            />
            <span>Dashboard</span>
          </>
        )}
      </NavLink>
      {navConfig.map((tab) => (
        <NavLink
          key={tab.path}
          className={({ isActive }) =>
            `px-8 py-5 flex items-center gap-x-2 ${
              isActive ? "text-[#D0680F] font-semibold bg-[#f7f6f5]" : ""
            }`
          }
          to={tab.path}
          onClick={() => setIsDrawerOpen(false)}
        >
          {({ isActive }) => (
            <>
              <ReactSVG
                src={tab.icon}
                beforeInjection={(svg) => {
                  svg.querySelectorAll("path").forEach((path) => {
                    path.setAttribute("fill", isActive ? "#D0680F" : "#6B7280");
                  });
                }}
                className="w-6 h-6 flex items-center"
              />
              <span>{tab.title}</span>
            </>
          )}
        </NavLink>
      ))}
    </>
  );

  return (
    <>
      {/* Mobile Burger Menu Button */}
      <button
        className="h-fit lg:hidden z-50"
        onClick={() => setIsDrawerOpen(true)}
      >
        <MenuIcon size={24} />
      </button>

      {/* Mobile Drawer */}
      <MobileDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <SidebarContent />
      </MobileDrawer>

      {/* Desktop Sidebar */}
      <div className="hidden lg:block w-full h-screen flex-col bg-white rounded-[10px] shadow border border-[#e0e4ea]">
        <SidebarContent />
      </div>
    </>
  );
};

export default DashboardSidebar;
