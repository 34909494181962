import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  fetchHomeownerDealByID,
  patchDealStatus,
} from "services/installerServices";
import BreadCrumb from "./BreadCrumb";
import DealOverviewSkeleton from "../skeleton/shared/DealOverviewSkeleton";
import { GalleryCarousel } from "./GalleryCarousel";
import { CustomizeTabs } from "../MUI/CustomizeTabs";
import SelectComponent from "../MUI/SelectComponent";
import ExportDealOverview from "../installer/ExportDesignPDF";
import {
  fetchHomeownerDealOverview,
  getUploadedSurvey,
} from "services/homeOwnerServices";
import Header from "./Header";
import { formatPrice } from "utils/formatNumber";
import PanelMap from "PanelMap";

const CustomToast = ({ status, progress }) => (
  <div className="flex flex-col gap-y-2 w-full rounded-lg px-4 py-3">
    <div className="flex items-center gap-x-3">
      <img src="/icons/export-icon.svg" alt="export" className="w-7 h-7" />
      <h3 className="text-sm font-medium text-gray-900">{status}</h3>
    </div>

    <div className="flex items-center w-full">
      <div className="relative h-1.5 w-full bg-gray-100 rounded-full overflow-hidden">
        <div
          className="absolute left-0 top-0 h-full bg-blue-500 transition-all duration-300 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
      <span className="text-xs font-medium text-gray-900 w-1/6">{`${progress.toFixed()}%`}</span>
    </div>
  </div>
);
const DealOverview = () => {
  const dashboardSidebar = useOutletContext();
  const params = useParams();
  const [dealData, setDealData] = useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [indexToStart, setIndexToStart] = useState(null);
  const [Exporting, setExporting] = useState(false);
  const [surveyData, setSurveyData] = useState({});

  const toastId = useRef(null);
  const navigate = useNavigate();

  const isHomeOwner = localStorage.getItem("USER") === "homeOwner";

  const homeOwnerData = isHomeOwner
    ? dealData?.allOffersDeals?.User
    : dealData?.Deal_details?.User;

  const solarInsightData = isHomeOwner
    ? dealData?.allOffersDeals?.User_solar_insight
    : dealData?.Deal_details?.User_solar_insight;

  const dealDetails = isHomeOwner
    ? dealData?.allOffersDeals
    : dealData?.Deal_details;

  const surveyDetails = isHomeOwner ? surveyData : dealData?.Survey_Details;

  const homeOwnerFullName = `${homeOwnerData?.first_name} ${homeOwnerData?.last_name}`;
  const estimatedAnnualProductionFixed = formatPrice(
    solarInsightData?.Estimated_Annual_production
  );

  const isInstallerSigned = dealDetails?.installer_contract_signed;
  const isHomeOwnerSigned = dealDetails?.homeOwner_contract_signed;
  const isBothSigned = isHomeOwnerSigned && isInstallerSigned;
  const handleSelectChange = async (event) => {
    if (isHomeOwner) return;
    try {
      setIsLoading(true);
      const res = await patchDealStatus(params.userId, +event.target.value);
      setSelectedValue(event.target.value);
      toast.success(res.message);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onCloseAfter = () => {
    setIndexToStart(null);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    if (isHomeOwner) {
      try {
        setLoading(true);
        const res = await fetchHomeownerDealOverview();
        const surveyData = await getUploadedSurvey().catch((surveyError) => {
          console.error("Error fetching survey data:", surveyError);
          return {}; // Fallback value if getUploadedSurvey fails
        });
        setDealData(res);
        setSurveyData(surveyData);
        setSelectedValue(res.allOffersDeals.status);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await fetchHomeownerDealByID(params.userId);
        setDealData(res);
        setSelectedValue(res.Deal_details.status);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const cleanupToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  };
  useEffect(() => {
    if (!Exporting?.status) {
      cleanupToast();
      return;
    }

    const toastOptions = {
      position: "top-right",
      autoClose: false,
      className: "m-0 bg-[#f8feff] pt-3 pr-3 border-l-[7px] border-[#287192]",
      bodyClassName: "p-0 m-0",
      toastClassName: "hidden",
      progressClassName: "hidden",
    };

    const toastContent = (
      <CustomToast status={Exporting.status} progress={Exporting.progress} />
    );

    if (toastId.current) {
      toast.update(toastId.current, {
        render: toastContent,
        ...toastOptions,
      });
    } else {
      toastId.current = toast(toastContent, toastOptions);
    }

    if (Exporting.progress === 100) {
      const timeoutId = setTimeout(() => {
        cleanupToast();
        setExporting(null);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }

    return () => {
      if (Exporting?.progress === 100) {
        cleanupToast();
      }
    };
  }, [Exporting?.status, Exporting?.progress]);

  if (loading) {
    return <DealOverviewSkeleton />;
  }

  const options = [
    { value: "0", label: "Active" },
    { value: "1", label: "Closed Won" },
    { value: "2", label: "Closed Lost" },
  ];

  const tabs = [
    {
      label: "To Do",
      value: 0,
      sx: { color: "#000", fontSize: 16 },
      disableRipple: true,
      ariaLabel: "First tab",
    },
    {
      label: "Pending",
      value: 1,
      sx: { color: "#000", fontSize: 16 },
      disableRipple: true,
      ariaLabel: "Second tab",
    },
    {
      label: "Done",
      value: 2,
      sx: { color: "#000", fontSize: 16 },
      disableRipple: true,
      ariaLabel: "Third tab",
      onClick: () => console.log("Done tab clicked"),
    },
  ];
  const tabPanels = [
    {
      content: (
        <>
          {!isInstallerSigned && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#f7f6f5] rounded-lg shadow"
              onClick={() => {
                if (!isHomeOwnerSigned) {
                  return toast.error(
                    "You cannot sign the contract as an installer before homeowner signs the contract"
                  );
                } else if (!dealData.Survey_Details?.isSiteSurveyCompleted) {
                  return toast.error(
                    "You cannot sign the contract as an installer before homeowner complete the site survey."
                  );
                }
                navigate(
                  `/dashboard-installer/Mydeals/${params.userId}/contract`
                );
              }}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Sign the contract
                    </h1>
                    <p>
                      Sign the contract to confirm your participation in this
                      deal!
                    </p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {!isBothSigned && isInstallerSigned && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#f7f6f5] rounded-lg shadow"
              onClick={() =>
                navigate(
                  `/dashboard-installer/Mydeals/${params.userId}/contract`
                )
              }
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Awaiting Homeowner Signature
                    </h1>
                    <p>The homeowner has not signed the contract yet.</p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {isBothSigned && (
            <div
              className="flex flex-col gap-y-4 cursor-pointer bg-[#f7f6f5] rounded-lg shadow"
              onClick={() =>
                navigate(
                  `/dashboard-installer/Mydeals/${params.userId}/contract`
                )
              }
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex gap-y-3 gap-x-2">
                  <img
                    loading="lazy"
                    className="self-start"
                    src="/icons/contract.svg"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-2">
                    <h1 className="text-[#112532] text-base font-semibold">
                      Contract Signed
                    </h1>
                    <p>The contact has been successfully signed.</p>
                  </div>
                </div>
                <img loading="lazy" src="/icons/right-pag.svg" alt="" />
              </div>
            </div>
          )}
        </>
      ),
    },
  ];
  const IndoorOutdoorPhotos = ({ surveyData }) => {
    const indoorPhotos = [
      { key: "subPanel", label: "Sub Panel" },
      { key: "subPanel_Label", label: "Sub Panel Label" },
      { key: "subPanel_Location", label: "Sub Panel Location" },
      { key: "attic", label: "Attic" },
    ];

    const outdoorPhotos = [
      { key: "mainService_panel", label: "Main Service Panel" },
      { key: "mainService_panel_label", label: "Main Service Panel Label" },
      { key: "main_Breaker", label: "Main Breaker" },
      { key: "area_surrounding_panel", label: "Area Surrounding Panel" },
      { key: "utlity_meter", label: "Utility Meter" },
      { key: "meter_location", label: "Meter Location" },
      { key: "roof_location", label: "Roof Location" },
    ];

    const renderImageBlock = (key, label, src, index) => {
      return src ? (
        <div className="relative group w-[132px] h-[132px]" key={key}>
          <img
            loading="lazy"
            className="w-full h-full rounded"
            src={src}
            alt={label}
          />
          <div className="absolute inset-0 z-10 flex items-start justify-end p-2 md:opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <p className="text-white bg-black bg-opacity-60 rounded p-1 text-sm">
              {label}
            </p>
            <img
              loading="lazy"
              onClick={() => setIndexToStart(index)}
              src="/icons/full-screen.svg"
              className="w-4 h-4 ml-2 cursor-pointer"
              alt="icon"
            />
          </div>
        </div>
      ) : (
        <div
          className="w-[132px] h-[132px] bg-gray-200 flex flex-col items-center justify-center rounded"
          key={key}
        >
          <img loading="lazy" src="/icons/noimage.svg" alt={label} />
          <p className="text-gray-500 text-center">{label}</p>
        </div>
      );
    };

    let validIndex = -1;

    return (
      <div className="flex flex-col gap-y-5">
        <h1 className="text-[#112532] font-semibold">Indoor Photos</h1>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {indoorPhotos.map(({ key, label }) => {
            const src = surveyData?.[key];
            if (src) validIndex++;
            return renderImageBlock(key, label, src, validIndex);
          })}
        </div>

        <h1 className="text-[#112532] font-semibold">Outdoor Photos</h1>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {outdoorPhotos.map(({ key, label }) => {
            const src = surveyData?.[key];
            if (src) validIndex++;
            return renderImageBlock(key, label, src, validIndex);
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      {isHomeOwner && <Header />}
      <div
        className={
          "flex flex-col lg:flex-row justify-between p-4 gap-4" +
          (isHomeOwner ? " md:px-52" : " md:px-0")
        }
      >
        <div className="flex-1 flex flex-col gap-4">
          <div className="breadcrumb-section">
            <BreadCrumb
              paths={
                isHomeOwner
                  ? [
                      { name: "Dashboard", pathLink: "/dashboard" },
                      {
                        name: "Deal overview",
                        pathLink: `/dashboard/deal-overview`,
                      },
                    ]
                  : [
                      {
                        name: "My Deals",
                        pathLink: "/dashboard-installer/Mydeals",
                      },
                      {
                        name: "Deal Overview",
                        pathLink: `/dashboard-installer/Mydeals/${params.userId}`,
                      },
                    ]
              }
            />
          </div>

          <div className="px-4 lg:px-8 py-6 flex flex-col gap-4 rounded-lg shadow border border-[#d9d3cf] bg-white">
            <div className="flex items-center gap-4">
              <img
                loading="lazy"
                className="w-16 h-16 rounded-full"
                src={
                  homeOwnerData?.profile_img || "/images/default-profile.svg"
                }
                alt=""
              />
              <h1 className="text-[#112532] text-2xl lg:text-[33px] font-semibold">
                {homeOwnerFullName}
              </h1>
            </div>
            <div className="flex justify-between gap-4">
              <p className="text-[#112532] font-bold">
                <span className="text-[#244255] font-normal">Deal ID: </span>
                {dealDetails?.id} /{" "}
                {dealDetails?.homeOwner_contract_signed ? "Contract" : "Lead"}
              </p>
              {!isHomeOwner ? (
                <SelectComponent
                  options={options}
                  value={selectedValue.toString()}
                  onChange={handleSelectChange}
                  iconComponent={KeyboardArrowDownOutlinedIcon}
                />
              ) : (
                <div
                  className={
                    selectedValue
                      ? "text-red-600 text-[13px] font-bold p-1 bg-red-100"
                      : "text-emerald-900 text-[13px] font-bold p-1 bg-emerald-50"
                  }
                >
                  {options[selectedValue].label}
                </div>
              )}
            </div>
            <hr />
            <div className="space-y-2">
              <p className="flex gap-2 py-2 text-[#265D78] underline">
                <img loading="lazy" src="/icons/Email.svg" alt="mail icon" />
                {homeOwnerData?.email}
              </p>
              <p className="flex gap-2 py-2">
                <img loading="lazy" src="/icons/Call.svg" alt="phone icon" />
                {homeOwnerData?.mobile}
              </p>
              <p className="flex gap-2 py-2">
                <img
                  loading="lazy"
                  src="/icons/Location.svg"
                  alt="location icon"
                />
                {homeOwnerData?.location}
              </p>
            </div>
          </div>

          {/* Deal Tracker for Mobile */}
          {!isHomeOwner && (
            <div className="lg:hidden flex flex-col gap-4 border-1 px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
              <h1 className="text-[19px] text-[#112532] font-semibold ">
                Deal Tracker
              </h1>
              <CustomizeTabs
                value={value}
                onChange={handleChange}
                tabs={tabs}
                tabPanels={tabPanels}
              />
            </div>
          )}

          <div className="flex justify-between py-4 px-4 lg:px-0">
            <h1 className="text-[#112532] text-xl lg:text-[23px] font-semibold">
              Deal Details
            </h1>
            <ExportDealOverview
              dealData={{ ...dealData, ...surveyData }}
              setExporting={setExporting}
            />
          </div>

          <div className="px-4 lg:px-8 py-6 flex flex-col gap-y-5 rounded-lg shadow border border-[#d9d3cf] bg-white">
            <h1 className="text-[#112532] text-lg lg:text-[19px] font-semibold">
              Solar Design
            </h1>
            <div className="flex gap-3 px-4 py-3 bg-[#F8FEFF] border-l-[5px] border-[#287192] rounded">
              <img
                className="w-7 h-7"
                loading="lazy"
                src="/icons/info.svg"
                alt="info icon"
              />
              <p className="text-sm lg:text-[14px]">
                Based on the location
                <span className="font-semibold">
                  {` ${homeOwnerData?.location} `}
                </span>
                we recommended a base of
                <span className="font-semibold">
                  {` ${formatPrice(solarInsightData?.system_size)} `}
                  kW
                </span>
                to cover your current bill of
                <span className="font-semibold">
                  ${`${homeOwnerData?.avg_bill} `}
                </span>
              </p>
            </div>

            {solarInsightData?.panels_data && (
              <PanelMap
                buildingInsights={JSON.parse(solarInsightData?.panels_data)}
                staticPanelsNumber={solarInsightData?.userRequestedPanels}
              />
            )}
            <div className="px-4 py-4">
              <div className="flex items-center gap-2 mb-2">
                <img
                  loading="lazy"
                  src="/icons/step3.png"
                  alt="step3"
                  width={30}
                />
                <span>Solar design</span>
              </div>
              <div className="flex lg:grid lg:grid-cols-3 gap-4 overflow-x-auto pb-4 lg:overflow-visible">
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {dealDetails?.panels_number}
                  </h1>
                  <p>solar panels</p>
                </div>
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {formatPrice(solarInsightData?.system_size)} <sub>kW</sub>
                  </h1>
                  <p>System size</p>
                </div>
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {estimatedAnnualProductionFixed}
                    <sub>kWh</sub>
                  </h1>
                  <p>Estimate annual production</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="px-4 py-2">
              <div className="flex items-center gap-2 mb-2">
                <img
                  loading="lazy"
                  src="/icons/bill.png"
                  alt="step3"
                  width={30}
                />
                <span>Bills Coverage</span>
              </div>
              <div className="flex lg:grid lg:grid-cols-3 gap-4 overflow-x-auto pb-4 lg:overflow-visible">
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    {formatPrice(solarInsightData?.estimated_offset * 100)}%
                  </h1>
                  <p>Estimated offset</p>
                </div>
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    ${homeOwnerData?.avg_bill}
                  </h1>
                  <p>Old bill</p>
                </div>
                <div className="md:border-0 border border-[#D9D3CF] rounded-lg px-3 py-2 whitespace-nowrap">
                  <h1 className="mb-2 text-[#112532] text-2xl lg:text-[28px] font-bold whitespace-nowrap">
                    ${formatPrice(solarInsightData?.left_over_elec_bill)}
                  </h1>
                  <p>Leftover electricity bill amount</p>
                </div>
              </div>
            </div>
          </div>

          {/* System Tier Section */}
          <div className="flex flex-col gap-y-5 px-4 lg:px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
            <h1 className="text-[19px] text-[#112532] font-semibold">
              System Tier
            </h1>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-x-2">
                <img
                  loading="lazy"
                  className="self-start"
                  src="/icons/Stars.svg"
                  alt=""
                />
                <h1 className="bg-clip-text text-transparent bg-custom-gradient text-[19px] font-semibold">
                  {dealDetails?.system_type}
                </h1>
              </div>
              <div className="flex flex-col gap-y-2">
                <p className="text-[#64748B]">
                  Opt for maximum efficiency and long-term savings
                </p>
                <p className="flex flex-col sm:flex-row gap-3">
                  <span className="flex items-center">
                    <img
                      loading="lazy"
                      src="/icons/step3.png"
                      alt=""
                      width={"24px"}
                    />{" "}
                    REC panels or similar
                  </span>
                  <span className="flex items-center">
                    <img loading="lazy" src="/icons/shield.svg" alt="" /> 25
                    years warranty
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Site Survey Section */}
          <div className="site-survey-section flex flex-col gap-y-5 px-4 lg:px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
            <div className="flex justify-between">
              <h1 className="text-[19px] text-[#112532] font-semibold">
                Site Survey
              </h1>
              {(isHomeOwner ? surveyData.survey : surveyDetails?.survey) && (
                <GalleryCarousel
                  onCloseAfter={onCloseAfter}
                  indexToStart={indexToStart}
                  imageData={
                    isHomeOwner ? surveyData.survey : surveyDetails?.survey
                  }
                  URLsFields={[
                    { key: "subPanel", label: "Sub Panel" },
                    { key: "subPanel_Label", label: "Sub Panel Label" },
                    { key: "subPanel_Location", label: "Sub Panel Location" },
                    { key: "attic", label: "Attic" },
                    { key: "mainService_panel", label: "Main Service Panel" },
                    {
                      key: "mainService_panel_label",
                      label: "Main Service Panel Label",
                    },
                    { key: "main_Breaker", label: "Main Breaker" },
                    {
                      key: "area_surrounding_panel",
                      label: "Area Surrounding Panel",
                    },
                    { key: "utlity_meter", label: "Utility Meter" },
                    { key: "meter_location", label: "Meter Location" },
                    { key: "roof_location", label: "Roof Location" },
                  ]}
                />
              )}
            </div>

            {(isHomeOwner ? surveyData.survey : surveyDetails?.survey) ? (
              <IndoorOutdoorPhotos
                surveyData={
                  isHomeOwner ? surveyData.survey : surveyDetails?.survey
                }
              />
            ) : (
              <div className="flex flex-col items-center gap-y-5">
                <img
                  loading="lazy"
                  className="w-14 h-14"
                  src="/icons/material-symbols_photo-library.svg"
                  alt=""
                />
                <p className="text-center">
                  No photos of the site yet. Want to schedule an appointment
                  with {homeOwnerFullName}
                </p>
              </div>
            )}
          </div>

          {/* Additional Information Section */}
          <div className="flex flex-col gap-y-4 px-4 lg:px-8 py-6 rounded-lg shadow border border-[#d9d3cf] bg-white">
            <div className="flex flex-col gap-y-6">
              <h1 className="text-[19px] text-[#112532] font-semibold">
                Additional Information
              </h1>
              <div className="flex flex-col gap-y-3">
                <p className="text-[#475569]">Roof Type</p>
                <div className="flex items-center">
                  <img
                    loading="lazy"
                    src={"/images/metal.png"}
                    alt="metal"
                    style={{
                      marginLeft: "10px",
                      width: "40px",
                      height: "40px",
                    }}
                    className="me-4"
                  />
                  <p className="text-[#475569] font-semibold">
                    {homeOwnerData?.roof_type}
                  </p>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-6">
                <div className="flex flex-col gap-y-3 w-full sm:w-1/3">
                  <p className="text-[#475569]">Roof Condition</p>
                  <p className="font-semibold">
                    {homeOwnerData?.roof_condition}
                  </p>
                </div>
                <div className="flex flex-col gap-y-3 w-full sm:w-1/3">
                  <p className="text-[#475569]">Estimated Credit Score</p>
                  <p className="font-semibold">{homeOwnerData?.credit_score}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Deal Tracker for Desktop */}
        {!isHomeOwner && (
          <div className="hidden bg-white lg:flex w-1/3 h-screen flex-col gap-4 border-1 px-8 py-6 rounded-lg shadow border border-[#d9d3cf]">
            <h1 className="text-[19px] text-[#112532] font-semibold">
              Deal Tracker
            </h1>
            <CustomizeTabs
              value={value}
              onChange={handleChange}
              tabs={tabs}
              tabPanels={tabPanels}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DealOverview;
