/* global google */

import React, { useCallback, useRef } from "react";
import { GoogleMap, Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import useResponsive from "hooks/useResponsive";
import * as geotiff from "geotiff";
import * as geokeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const ironPalette = ["00000A", "91009C", "E64616", "FEB400", "FFFFF6"];

// Utility functions for color handling and normalization
function normalize(x, max = 1, min = 0) {
  const y = (x - min) / (max - min);
  return clamp(y, 0, 1);
}

function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function lerp(x, y, t) {
  return x + t * (y - x);
}

function colorToRGB(color) {
  const hex = color.startsWith("#") ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
}

function createPalette(hexColors) {
  // Map each hex color into an RGB value
  const rgb = hexColors.map(colorToRGB);
  // Create a palette with 256 colors derived from our rgb colors
  const size = 256;
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      // Get the lower and upper indices for each color
      const index = i * step;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      // Interpolate between the colors to get the shades
      return {
        r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
        g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
        b: lerp(rgb[lower].b, rgb[upper].b, index - lower),
      };
    });
}

const downloadGeoTIFF = async (url, apiKey) => {
  const solarUrl = url.includes("solar.googleapis.com")
    ? url + `&key=${apiKey}`
    : url;

  const response = await fetch(solarUrl);
  if (response.status !== 200) {
    const error = await response.json();
    console.error(`Download GeoTIFF failed: ${url}\n`, error);
    throw error;
  }

  const arrayBuffer = await response.arrayBuffer();
  const tiff = await geotiff.fromArrayBuffer(arrayBuffer);
  const image = await tiff.getImage();
  const rasters = await image.readRasters();

  const geoKeys = image.getGeoKeys();
  // const projString = getProjectionString(geoKeys);
  const projObj = geokeysToProj4.toProj4(geoKeys);
  const projection = proj4(projObj.proj4, "WGS84");
  const box = image.getBoundingBox();
  const sw = projection.forward({
    x: box[0] * projObj.coordinatesConversionParameters.x,
    y: box[1] * projObj.coordinatesConversionParameters.y,
  });
  const ne = projection.forward({
    x: box[2] * projObj.coordinatesConversionParameters.x,
    y: box[3] * projObj.coordinatesConversionParameters.y,
  });

  return {
    width: image.getWidth(),
    height: image.getHeight(),
    rasters,
    bounds: { north: ne.y, south: sw.y, east: ne.x, west: sw.x },
  };
};

const renderPalette = ({
  data,
  mask,
  colors,
  min,
  max,
  buildingBounds,
  index = 0,
}) => {
  // Create a palette from the hex colors
  const palette = createPalette(colors);

  // Create canvas with data dimensions
  const canvas = document.createElement("canvas");
  canvas.width = data.width;
  canvas.height = data.height;
  const ctx = canvas.getContext("2d");
  const imageData = ctx.createImageData(data.width, data.height);

  // Calculate pixel coordinates for building bounds
  const pixelWidth = (data.bounds.east - data.bounds.west) / data.width;
  const pixelHeight = (data.bounds.north - data.bounds.south) / data.height;

  const buildingMinX = Math.floor(
    (buildingBounds.west - data.bounds.west) / pixelWidth
  );
  const buildingMaxX = Math.ceil(
    (buildingBounds.east - data.bounds.west) / pixelWidth
  );
  const buildingMinY = Math.floor(
    (data.bounds.north - buildingBounds.north) / pixelHeight
  );
  const buildingMaxY = Math.ceil(
    (data.bounds.north - buildingBounds.south) / pixelHeight
  );

  // Get the values from the selected raster
  const values = data.rasters[index];
  const maskValues = mask ? mask.rasters[0] : null;

  // Normalize the values and map them to palette indices
  const indices = values
    .map((x) => normalize(x, max, min))
    .map((x) => Math.round(x * (palette.length - 1)));

  for (let y = 0; y < data.height; y++) {
    for (let x = 0; x < data.width; x++) {
      const i = y * data.width + x;

      // Check if pixel is within building bounds
      const isInBuilding =
        x >= buildingMinX &&
        x <= buildingMaxX &&
        y >= buildingMinY &&
        y <= buildingMaxY;

      if (isInBuilding && (!maskValues || maskValues[i])) {
        const paletteIndex = indices[i];
        const color = palette[paletteIndex];

        const j = i * 4;
        imageData.data[j] = color.r; // Red
        imageData.data[j + 1] = color.g; // Green
        imageData.data[j + 2] = color.b; // Blue
        imageData.data[j + 3] = 255; // Alpha
      }
    }
  }

  ctx.putImageData(imageData, 0, 0);
  return canvas;
};

// Utility function for consistent coordinate offset
const applyCoordinateOffset = (latitude, longitude) => {
  return {
    lat: latitude + 0.000016,
    lng: longitude + 0.000005,
  };
};

const PanelMap = ({ buildingInsights, staticPanelsNumber }) => {
  const { isSm } = useResponsive();
  const formData = useSelector((state) => state.homeownerForm);
  const mapRef = useRef(null);
  const overlayRef = useRef(null);
  const panels = buildingInsights?.panelsWithCorners || [];
  const solarPotential = buildingInsights;

  // Panel dimensions in meters
  const PANEL_WIDTH_METERS = solarPotential?.panelWidthMeters || 1.7;
  const PANEL_HEIGHT_METERS = solarPotential?.panelHeightMeters || 1.0;

  const centerBuilding = buildingInsights?.centerBuilding;
  const defaultCenter = applyCoordinateOffset(
    centerBuilding?.latitude,
    centerBuilding?.longitude
  );
  const mapCenter = {
    lat: centerBuilding?.latitude,
    lng: centerBuilding?.longitude,
  };

  const renderDataLayer = async (map) => {
    try {
      if (
        !buildingInsights?.dataLayersResponse?.annualFluxUrl ||
        !buildingInsights?.dataLayersResponse?.maskUrl
      ) {
        console.warn("Missing required URLs for data layer rendering");
        return;
      }

      const [mask, data] = await Promise.all([
        downloadGeoTIFF(
          buildingInsights.dataLayersResponse.maskUrl,
          process.env.REACT_APP_GOOGLE_API_KEY
        ),
        downloadGeoTIFF(
          buildingInsights.dataLayersResponse.annualFluxUrl,
          process.env.REACT_APP_GOOGLE_API_KEY
        ),
      ]);

      const buildingBounds = {
        north: buildingInsights.boundingBox.ne.latitude,
        south: buildingInsights.boundingBox.sw.latitude,
        east: buildingInsights.boundingBox.ne.longitude,
        west: buildingInsights.boundingBox.sw.longitude,
      };

      const canvas = renderPalette({
        data: data,
        mask: mask,
        colors: ironPalette,
        min: 0,
        max: 1800,
        buildingBounds,
      });

      const imageUrl = canvas.toDataURL();

      // Apply the same offset to the bounds as we do to the panels
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          data.bounds.south + 0.000016,
          data.bounds.west + 0.000005
        ),
        new google.maps.LatLng(
          data.bounds.north + 0.000016,
          data.bounds.east + 0.000005
        )
      );

      if (overlayRef.current) {
        overlayRef.current.setMap(null);
      }

      const overlay = new google.maps.GroundOverlay(imageUrl, bounds);
      overlay.setMap(map);
      overlayRef.current = overlay;
    } catch (error) {
      console.error("Error rendering data layer:", error);
    }
  };

  const calculatePanelPoints = (
    center,
    orientation,
    segmentAzimuth,
    pitchDegrees
  ) => {
    let halfWidth = PANEL_WIDTH_METERS / 2;
    let halfHeight = PANEL_HEIGHT_METERS / 2;
    const pitchRadians = (pitchDegrees * Math.PI) / 180;

    if (orientation === "PORTRAIT") {
      halfHeight *= Math.cos(pitchRadians);
    } else {
      halfWidth *= Math.cos(pitchRadians);
    }

    const basePoints = [
      { x: +halfWidth, y: +halfHeight },
      { x: +halfWidth, y: -halfHeight },
      { x: -halfWidth, y: -halfHeight },
      { x: -halfWidth, y: +halfHeight },
    ];

    const orientationAngle = orientation === "PORTRAIT" ? 90 : 0;
    const totalRotation = orientationAngle + segmentAzimuth;

    // Use the consistent offset function
    const offsetCenter = applyCoordinateOffset(
      center.latitude,
      center.longitude
    );

    return basePoints.map(({ x, y }) => {
      const distance = Math.sqrt(x ** 2 + y ** 2);
      const angle = (Math.atan2(y, x) * (180 / Math.PI) + totalRotation) % 360;
      return google.maps.geometry.spherical.computeOffset(
        offsetCenter,
        distance,
        angle
      );
    });
  };

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      map.setCenter(mapCenter);
      renderDataLayer(map);
    },
    [mapCenter]
  );

  const onUnmount = useCallback(() => {
    if (overlayRef.current) {
      overlayRef.current.setMap(null);
      overlayRef.current = null;
    }
    mapRef.current = null;
  }, []);

  if (!panels.length) {
    console.error("No panels data available.");
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={isSm ? 19 : 21}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        rotateControl: false,
        mapTypeControl: false,
        mapTypeId: "satellite",
        tilt: 0,
        draggable: true,
      }}
    >
      {panels
        .slice(0, staticPanelsNumber || formData.panels_number)
        .map((panel, index) => {
          const points = calculatePanelPoints(
            panel.center,
            panel.orientation,
            solarPotential.roofSegmentStats[panel.segmentIndex].azimuthDegrees,
            solarPotential.roofSegmentStats[panel.segmentIndex].pitchDegrees
          );

          return (
            <Polygon
              key={index}
              paths={points}
              options={{
                strokeColor: "#B0BEC5",
                strokeOpacity: 0.9,
                strokeWeight: 1,
                fillColor: "black",
                fillOpacity: 0.9,
              }}
            />
          );
        })}
    </GoogleMap>
  );
};

export default PanelMap;
