import React, { useEffect, useState } from "react";
import GalleryCarousel from "components/shared/GalleryCarousel";
import Comment from "components/shared/Comment";
import { GETinstallerProfileById } from "../../services/AdminServices";
import { useParams } from "react-router-dom";
import BreadCrumb from "../shared/BreadCrumb";
import InstallerPortfolioSkeleton from "../skeleton/shared/InstallerPortfolioSkeleton";

const InstallerProfile = () => {
  const [visibleReviews, setVisibleReviews] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [indexToStart, setIndexToStart] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const params = useParams();
  const installer = portfolioData?.installer[0];
  const hasReviews = portfolioData?.reviewSummary?.totalReviews > 0;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await GETinstallerProfileById(params.userId);
      setPortfolioData(res);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewMore = () => {
    setVisibleReviews((prev) => prev + 10);
  };

  const onCloseAfter = () => setIndexToStart(null);

  if (isLoading) {
    return (
      <>
        <div className="flex-1">
          <InstallerPortfolioSkeleton />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="pl-8">
        <BreadCrumb
          paths={[
            { name: "All Homeowners", pathLink: "/admin-dashboard/homeowners" },
            {
              name: "Installer Details",
              pathLink: `/admin-dashboard/homeowners/installer/${params.userId}`,
            },
          ]}
        />
      </div>
      <div className="flex flex-col gap-y-8 px-8 py-6">
        <div className="px-4 md:px-8 py-4 md:py-6 bg-white rounded-lg shadow border border-gray-200">
          <div className="flex items-center gap-4">
            <img
              loading="lazy"
              className="w-12 h-12 md:w-16 md:h-16 rounded-full"
              src={installer?.logo || "/images/defaultLogo.svg"}
              alt={`${installer?.business_name} logo`}
            />
            <h1 className="text-[#112532] text-xl md:text-3xl font-semibold">
              {installer?.business_name}
            </h1>
          </div>

          <div className="flex flex-col gap-y-4 mt-4 md:ml-20 md:mt-0">
            <div className="flex items-center gap-x-2">
              {!hasReviews ? (
                <div className="flex gap-x-2 items-center">
                  <img src="/icons/off-star.svg" alt="empty star" />
                  <h1 className="text-[#112532] text-lg md:text-2xl">
                    Not Rated
                  </h1>
                </div>
              ) : (
                <>
                  <h1 className="text-[#112532] text-xl md:text-3xl font-bold">
                    {portfolioData?.reviewSummary.averageRating}
                    <span className="text-lg md:text-2xl font-semibold">/</span>
                    <span className="text-base md:text-xl font-normal">5</span>
                  </h1>
                  <img loading="lazy" src="/icons/star.svg" alt="star icon" />
                </>
              )}
              <span className="text-[#5e514e] text-sm md:text-base">
                ({portfolioData?.reviewSummary.totalReviews} Reviews)
              </span>
            </div>

            <div className="flex flex-wrap gap-2 md:gap-x-2.5">
              {portfolioData?.installerTags?.map((tag) => (
                <span
                  key={tag.id}
                  className="flex gap-x-2 items-center px-3 md:px-4 py-1 bg-[#F0FAFB] text-black border-1 border-[#D8F0F5] text-sm md:text-base rounded"
                >
                  {tag.tag}
                </span>
              ))}
            </div>
          </div>
          <hr className="border-[#D9D3CF]" />
          <div className="space-y-2">
            <p className="flex gap-2 py-2 text-sm text-[#265D78] underline">
              <img loading="lazy" src="/icons/Email.svg" alt="mail icon" />

              {installer?.email}
            </p>
            <p className="flex gap-2 py-2 text-sm">
              <img loading="lazy" src="/icons/Call.svg" alt="phone icon" />
              {installer?.mobile}
            </p>
            <p className="flex gap-2 py-2 text-sm">
              <img
                loading="lazy"
                src="/icons/Location.svg"
                alt="location icon"
              />
              {installer?.business_address}
            </p>
          </div>
        </div>
        {/* Projects Section */}
        <div className="px-4 md:px-8 py-4 md:py-6 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between gap-4 md:gap-0 mb-2">
              <h2 className="text-[#112532] text-xl md:text-[23px] font-semibold">
                Projects
              </h2>
            </div>

            {portfolioData?.portfolio_images?.length > 0 ? (
              <>
                <GalleryCarousel
                  onCloseAfter={onCloseAfter}
                  indexToStart={indexToStart}
                  imageData={portfolioData.portfolio_images}
                  variant="portfolio"
                  isRight={false}
                />
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5">
                  {portfolioData?.portfolio_images?.map((project, index) => (
                    <div key={project.id} className="group relative">
                      <img
                        loading="lazy"
                        src={project.img}
                        alt=""
                        className="h-48 md:h-64 w-full object-contain"
                      />
                      <div className="p-1 w-8 h-8 flex md:hidden group-hover:flex flex-col gap-y-2 justify-center items-center absolute right-0 top-[20px]">
                        <img
                          loading="lazy"
                          onClick={() => setIndexToStart(index)}
                          src="/icons/full-screen.svg"
                          className="w-full h-full cursor-pointer"
                          alt="icon"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="w-full flex flex-col items-center gap-y-5 py-4 md:py-6 px-4 md:px-8">
                <div className="w-full flex flex-col items-center gap-y-4">
                  <img
                    className="w-12 h-12 md:w-14 md:h-14"
                    src="/icons/gallery-library.svg"
                    alt=""
                  />
                  <h2 className="text-[#112532] text-lg md:text-[19px] font-semibold text-center">
                    No projects yet!
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 md:px-8 py-4 md:py-6 flex flex-col gap-y-4 bg-white rounded-[10px] shadow border border-[#d9d3cf]">
          <div className="flex gap-x-4 md:gap-x-20 gap-y-6">
            <div className="flex flex-col justify-evenly items-start md:items-center gap-y-4">
              <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
                Reviews
              </h1>
              {portfolioData?.reviewSummary?.totalReviews === 0 ? (
                <div className="flex items-center gap-x-2">
                  <h1 className="text-[#112532] text-lg md:text-xl">
                    Not Rated
                  </h1>
                  <img
                    className="h-6 md:h-8"
                    src="/icons//off-star.svg"
                    alt=""
                  />
                </div>
              ) : (
                <div className="flex gap-x-1">
                  <span className="text-2xl md:text-[39px] font-bold text-[#112532]">
                    {portfolioData?.reviewSummary?.averageRating}
                  </span>
                  <img
                    loading="lazy"
                    className="w-6 md:w-9"
                    src="/icons/star.svg"
                    alt=""
                  />
                </div>
              )}
              <span className="text-[#5e514e] text-sm md:text-base">
                ({portfolioData?.reviewSummary?.totalReviews} Reviews)
              </span>
            </div>
            <div className="w-full md:w-auto space-y-2">
              {[5, 4, 3, 2, 1].map((rating) => (
                <div key={rating} className="flex items-center">
                  <span className="text-gray-600 w-6 md:w-8 text-sm md:text-base">
                    {rating}
                  </span>
                  <div className="w-full md:w-60 h-1 bg-gray-300 relative rounded">
                    <span
                      className="block absolute h-full bg-[#F3B33E] z-10 rounded"
                      style={{
                        width: `${portfolioData?.reviewSummary?.starPercentages[rating]}%`,
                      }}
                    ></span>
                  </div>
                  <span className="text-[#C0B8B1] ml-2 min-w-[40px] text-sm md:text-base">
                    {Math.round(
                      portfolioData?.reviewSummary?.starPercentages[rating]
                    ) || 0}
                    %
                  </span>
                </div>
              ))}
            </div>
          </div>
          <hr className="my-4 md:my-6 border-[2px] border-[#ECECEE]" />
          <div className="flex flex-col gap-y-8 md:gap-y-14 ">
            <h1 className="text-[#112532] text-xl md:text-[23px] font-semibold">
              All Reviews ({portfolioData?.reviewSummary?.totalReviews})
            </h1>
            {portfolioData?.InstallerReviews?.slice(0, visibleReviews).map(
              (review) => (
                <Comment
                  key={review.id}
                  reviewer={{
                    ...review,
                    name: `${review?.User?.first_name} ${review?.User?.last_name}`,
                    review: review?.comment,
                    rating: review?.stars,
                    created_at: review?.created_at,
                  }}
                />
              )
            )}
            {portfolioData?.reviews &&
              visibleReviews < portfolioData.reviews.length && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={handleViewMore}
                    className="px-6 py-2 bg-[#112532] text-white rounded-[5px] hover:bg-opacity-90 transition-colors"
                  >
                    View More Reviews
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallerProfile;
