import { yupResolver } from "@hookform/resolvers/yup";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Header from "components/shared/Header";
import HomeDetailsRegistration from "components/home-owner/auth/HomeDetailsRegistration";
import PersonalDetailsRegistration from "components/home-owner/auth/PersonalDetailsRegistration";
import YourSolarDesign from "components/home-owner/auth/YourSolarDesign";
import PanelMap from "PanelMap";
import SolarOffers from "components/home-owner/auth/SolarOffers";
import AdditionalInformation from "components/home-owner/auth/AdditionalInformation";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { POSTpanelsWithoutObs } from "services/general";
import {
  homeOwnerLogIn,
  POSTavailableOffers,
  POSTavailableTiers,
  POSThomeOwnerRegister,
  POSThomeOwnerTempUser,
  POSTsolarInsights,
  PUTtempUserUpdate,
} from "services/homeOwnerServices";
import {
  goHomeownerBackStep,
  proceedToNextHomeownerFormStep,
  resetHomeownerForm,
  updateHomeownerForm,
} from "../../redux/actions";
import SignContract from "components/shared/Contract";
import {
  additionalInformationSchema,
  homeDetailsSchema,
  personalDetailsSchema,
  yourSolarDesignSchema,
} from "validation-schema/homeOwner";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Constants
const MAP_DEFAULTS = {
  ZOOM: 27,
  CONTAINER_STYLE: {
    width: "100%",
    height: "100%",
    position: "static",
  },
  OPTIONS: {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    rotateControl: false,
    mapTypeControl: false,
    mapTypeId: "satellite",
    tilt: 0,
  },
};

const Solar = () => {
  // State Management
  const formData = useSelector((state) => state.homeownerForm);
  const dispatch = useDispatch();
  const [panels, setPanels] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [solarInsights, setSolarInsights] = useState([]);
  const [offers, setOffers] = useState([]);
  const [searchBox, setSearchBox] = useState(null);
  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isFirstRender = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrl = `https://sparksolar.io${location.pathname}`;

  // Form Setup
  const getValidationSchema = (step) =>
    ({
      0: homeDetailsSchema,
      1: personalDetailsSchema,
      2: yourSolarDesignSchema,
      4: additionalInformationSchema,
    })[step] || homeDetailsSchema;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    trigger,
    watch,
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(getValidationSchema(formData.currentStep)),
    mode: "onChange",
  });
  // Effects
  useEffect(() => {
    if (formData.currentStep > 0) {
      dispatch(resetHomeownerForm());
      sessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    const savedSurvey = sessionStorage.getItem("saved_register_survey");
    if (!savedSurvey) return;

    const parsedSurvey = JSON.parse(savedSurvey);
    dispatch(updateHomeownerForm(parsedSurvey));
    reset(parsedSurvey);

    // Load all stored data at once
    const loadStorageData = (key) => {
      try {
        return JSON.parse(sessionStorage.getItem(key));
      } catch (error) {
        console.error(`Error loading ${key} from session storage:`, error);
        return null;
      }
    };

    const panelsData = loadStorageData("panelsWithoutObs");
    const tiersData = loadStorageData("availableTiers");
    const insightsData = loadStorageData("solarInsights");
    const offersData = loadStorageData("availableOffers");

    if (panelsData && tiersData && insightsData) {
      setPanels(panelsData);
      setTiers(tiersData);
      setSolarInsights(insightsData);
    }
    if (offersData) {
      setOffers(offersData);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      trigger();
    }
  }, [formData.currentStep]);

  const extractRequiredBuildingData = (fullBuildingInsights) => {
    if (!fullBuildingInsights) return null;

    // Extract only the needed properties
    return {
      panelWidthMeters: fullBuildingInsights.panelWidthMeters || 1.7,
      panelHeightMeters: fullBuildingInsights.panelHeightMeters || 1.0,

      centerBuilding: {
        latitude: fullBuildingInsights.centerBuilding?.latitude,
        longitude: fullBuildingInsights.centerBuilding?.longitude,
      },

      boundingBox: {
        ne: {
          latitude: fullBuildingInsights.boundingBox?.ne?.latitude,
          longitude: fullBuildingInsights.boundingBox?.ne?.longitude,
        },
        sw: {
          latitude: fullBuildingInsights.boundingBox?.sw?.latitude,
          longitude: fullBuildingInsights.boundingBox?.sw?.longitude,
        },
      },

      dataLayersResponse: {
        annualFluxUrl: fullBuildingInsights.dataLayersResponse?.annualFluxUrl,
        maskUrl: fullBuildingInsights.dataLayersResponse?.maskUrl,
      },

      panelsWithCorners:
        fullBuildingInsights.panelsWithCorners?.map((panel) => ({
          center: {
            latitude: panel.center?.latitude,
            longitude: panel.center?.longitude,
          },
          orientation: panel.orientation,
          segmentIndex: panel.segmentIndex,
        })) || [],

      roofSegmentStats:
        fullBuildingInsights.roofSegmentStats?.map((segment) => ({
          azimuthDegrees: segment.azimuthDegrees,
          pitchDegrees: segment.pitchDegrees,
        })) || [],
    };
  };

  // Core Functions
  const updateLocationState = (locationData, latLng) => {
    const updatedData = {
      location: locationData,
      latitude: latLng.lat.toString(),
      longitude: latLng.lng.toString(),
      LatLng: latLng,
    };

    dispatch(updateHomeownerForm(updatedData));
    setValue("location", locationData);
    setValue("LatLng", latLng);
    setValue("latitude", latLng.lat.toString());
    setValue("longitude", latLng.lng.toString());
    trigger("location");

    if (map) {
      map.panTo(latLng);
      map.setZoom(MAP_DEFAULTS.ZOOM);
    }
  };

  const handleAddressUpdate = async (latLng) => {
    if (!geocoder) {
      console.warn("Geocoder not initialized");
      return;
    }

    try {
      const response = await geocoder.geocode({ location: latLng });
      if (!response.results[0]) {
        console.warn("No geocoding results found");
        return;
      }
      const addressComponents = response.results[0].address_components;

      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const state = stateComponent ? stateComponent.long_name : null;

      const locationData = {
        address: response.results[0].formatted_address,
        placeId: response.results[0].place_id,
        state,
      };
      updateLocationState(locationData, latLng);
    } catch (error) {
      console.error("Geocoding error:", error);
      toast.error("Error updating location. Please try again.");
    }
  };

  const handleMapInteractions = {
    onMapLoad: (mapInstance) => {
      setMap(mapInstance);
      if (window.google) {
        setGeocoder(new window.google.maps.Geocoder());
      }
    },

    onSearchBoxLoad: (autocomplete) => {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place?.formatted_address && place?.place_id) {
          setValue("location", {
            address: place.formatted_address,
            placeId: place.place_id,
          });
        }
      });
      setSearchBox(autocomplete);
    },

    onPlaceChanged: () => {
      if (!searchBox) return;

      const place = searchBox.getPlace();
      if (!place.geometry) {
        toast.error("Please select a valid location");
        return;
      }
      const stateComponent = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );

      const latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      const locationData = {
        address: place.formatted_address,
        placeId: place.place_id,
        state: stateComponent.long_name,
      };

      updateLocationState(locationData, latLng);
    },
  };

  const handleFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      localStorage.setItem("USER", "homeOwner");

      const steps = {
        0: async () => {
          dispatch(updateHomeownerForm(data));
          navigate("/get-started");
        },

        1: async () => {
          const userRes = await POSThomeOwnerTempUser({
            ...data,
            avg_bill: formData.bill_value,
            location: data.location.address,
          });
          localStorage.setItem("userId", userRes.userId || userRes?.user?.id);

          const payload = {
            latitude: +data.latitude,
            longitude: +data.longitude,
            bill_value: +data.bill_value,
          };

          const [panelsRes, insightsRes] = await Promise.all([
            POSTpanelsWithoutObs(payload),
            POSTsolarInsights(payload),
          ]);

          if (panelsRes?.closed_Bulding_Distance >= 50) {
            setShowModal(true);
            setIsLoading(false);
            return false; // Return false to indicate we should stop processing
          }

          sessionStorage.setItem("panelsWithoutObs", JSON.stringify(panelsRes));
          sessionStorage.setItem("solarInsights", JSON.stringify(insightsRes));

          setPanels(panelsRes);
          setSolarInsights(insightsRes);

          const tiersRes = await POSTavailableTiers({
            latitude: +data?.LatLng?.lat,
            longitude: +data?.LatLng?.lng,
            bill_value: data?.bill_value,
            userEquipment: data?.equipment,
            address: data.location.address,
            state: data.location.state,
          });
          sessionStorage.setItem(
            "availableTiers",
            JSON.stringify(tiersRes || [])
          );
          setTiers(tiersRes || []);
        },

        2: async () => {
          await PUTtempUserUpdate(localStorage.getItem("userId"), {
            ...data,
            systemSize: formData.systemSize,
            location: data.location.address,
          });

          const offersRes = await POSTavailableOffers({
            tier: data.tier,
            address: data.location.address,
            number_of_panels: formData.panels_number,
            systemSize: formData.systemSize,
            equipment: formData?.equipment,
            state: data.location.state,
          });

          sessionStorage.setItem("availableOffers", JSON.stringify(offersRes));
          setOffers(offersRes);
        },

        3: async () => {
          await PUTtempUserUpdate(localStorage.getItem("userId"), {
            ...data,
            systemSize: formData.systemSize,
            location: data.location.address,
          });
        },

        4: async () => {
          await POSThomeOwnerRegister(localStorage.getItem("userId"), {
            ...data,
            systemSize: formData.systemSize,
            location: data.location.address,
            avg_bill: data?.bill_value,
            panels_number: formData.panels_number,
            state: data.location.state,
            panels_data: JSON.stringify(extractRequiredBuildingData(panels)),
          });

          const loginRes = await homeOwnerLogIn(
            data.email,
            data.password,
            false
          );
          localStorage.setItem("token", loginRes.token);
          sessionStorage.clear();
        },
      };

      const stepResult = await steps[formData.currentStep]?.();

      if (stepResult === false) {
        return;
      }

      const surveyData = {
        ...data,
        currentStep: formData.currentStep + 1,
        panels_number: formData.panels_number,
      };
      sessionStorage.setItem(
        "saved_register_survey",
        JSON.stringify(surveyData)
      );
      isFirstRender.current = true;

      dispatch(
        proceedToNextHomeownerFormStep({
          ...formData,
          ...data,
          panels_number: formData.panels_number,
        })
      );
    } catch (error) {
      const hasNoOffers = error.config.url === "/home_owner/offers/all-tiers";

      if (formData.currentStep === 1 && hasNoOffers) {
        sessionStorage.setItem("availableTiers", JSON.stringify([]));
        setTiers([]);
        dispatch(proceedToNextHomeownerFormStep(data));
        const surveyData = {
          ...data,
          currentStep: formData.currentStep + 1,
          panels_number: formData.panels_number,
        };
        sessionStorage.setItem(
          "saved_register_survey",
          JSON.stringify(surveyData)
        );
      }

      if (
        error.response?.data?.error ===
          "Error fetching solar insights: Request failed with status code 404" ||
        error.response?.data?.message ===
          "Invalid or incomplete response from conflict handler"
      ) {
        return setShowModal(true);
      }

      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  // Render Functions
  const renderMap = (draggable = true) => {
    if (formData.currentStep === 2) {
      return <PanelMap buildingInsights={panels} />;
    }

    return (
      <GoogleMap
        mapContainerStyle={MAP_DEFAULTS.CONTAINER_STYLE}
        options={MAP_DEFAULTS.OPTIONS}
        center={formData.LatLng}
        zoom={MAP_DEFAULTS.ZOOM}
        onLoad={handleMapInteractions.onMapLoad}
        onClick={
          draggable
            ? (e) =>
                handleAddressUpdate({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                })
            : undefined
        }
      >
        <Marker
          position={formData.LatLng}
          draggable={draggable}
          onDragEnd={
            draggable
              ? (e) =>
                  handleAddressUpdate({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  })
              : undefined
          }
        />
        {formData.currentStep === 1 && (
          <div className="absolute left-2 top-3 flex flex-col gap-y-2">
            <div className="flex gap-x-4">
              <InfoCard
                label="Electricity Bill"
                value={`$${formData.bill_value}`}
              />
              <InfoCard
                label="Equipment Type"
                value={
                  formData.equipment === "solar" ? "solar" : "solar + battery"
                }
              />
            </div>
            <div className="px-2 py-1 bg-amber-50/90 rounded-sm flex gap-x-2">
              <img src="/icons/location_on.svg" alt="Location icon" />
              <span className="text-[#112532] text-sm font-semibold">
                {formData.location.address}
              </span>
            </div>
          </div>
        )}
      </GoogleMap>
    );
  };

  const renderFormStep = () => {
    const commonProps = {
      control,
      errors,
      onSubmit: handleSubmit(handleFormSubmit),
      currentStep: formData.currentStep,
      isValid,
      isLoading,
    };

    const handleBack = () => {
      // dispatch(goHomeownerBackStep());
      const surveyData = {
        ...formData,
        currentStep: formData.currentStep - 1,
        panels_number: formData.panels_number,
      };
      sessionStorage.setItem(
        "saved_register_survey",
        JSON.stringify(surveyData)
      );
      isFirstRender.current = false;
      dispatch(updateHomeownerForm(surveyData));
    };

    switch (formData.currentStep) {
      case 0:
        return (
          <HomeDetailsRegistration
            {...commonProps}
            onLoad={handleMapInteractions.onSearchBoxLoad}
            onPlaceChanged={handleMapInteractions.onPlaceChanged}
            onMarkerDragEnd={(e) =>
              handleAddressUpdate({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            }
            onMapLoad={handleMapInteractions.onMapLoad}
            onMapClick={(e) =>
              handleAddressUpdate({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            }
            renderMap={renderMap}
            setValue={setValue}
          />
        );
      case 1:
        return (
          <PersonalDetailsRegistration
            {...commonProps}
            onBack={handleBack}
            renderMap={renderMap}
            isLoading={isLoading}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        );
      case 2:
        return (
          <YourSolarDesign
            {...commonProps}
            onBack={handleBack}
            renderMap={renderMap}
            tiers={tiers.tiers}
            solarInsights={solarInsights}
          />
        );
      case 3:
        return (
          <SolarOffers
            {...commonProps}
            offers={offers.offers}
            onBack={handleBack}
            onSubmit={handleSubmit(handleFormSubmit)}
            homeOwnerAddress={offers.HomeOwnerAddress}
            setValue={setValue}
          />
        );
      case 4:
        return (
          <AdditionalInformation
            {...commonProps}
            watch={watch}
            onBack={handleBack}
            selectedOffer={{
              ...offers?.offers?.find(
                (offer) => offer.offerId === formData.offer_id
              ),
              tier: formData.tier,
            }}
          />
        );
      case 5:
        return <SignContract />;
      default:
        return null;
    }
  };

  // InfoCard Component for Map overlay
  const InfoCard = ({ label, value }) => (
    <div className="px-2 py-1 bg-amber-50/90 rounded-sm flex flex-col gap-y-1 gap-x-2">
      <span className="text-[#112532] text-[19px] font-semibold">{value}</span>
      <span className="text-[#112532] text-sm">{label}</span>
    </div>
  );

  return (
    <div className="overflow-hidden">
      {location.pathname !== "/" && (
        <Helmet>
          <title>Get Started- Spark Solar</title>
          <link rel="canonical" href={currentUrl} />
          <meta
            name="description"
            content="Begin your journey to clean, renewable energy with Spark Solar! Our AI-powered platform simplifies the process of customizing and installing solar panel systems tailored to your needs."
          />
          <meta
            name="keywords"
            content="get started with solar, solar panel customization, AI-powered solar solutions, ai powered solar solutions, renewable energy, Spark Solar, solar energy, clean energy, sustainable energy, solar power systems, buy solar panels online, buy solar equipment online"
          />
        </Helmet>
      )}
      <Header />
      <div className="p-6 md:px-20">
        <div className="md:flex items-end gap-x-8 justify-between">
          {renderFormStep()}
          {formData.currentStep <= 2 && (
            <div
              className={`min-w-[50%] h-[600px] rounded-lg shadow overflow-hidden hidden md:block ${
                formData.currentStep === 2 ? "self-start mt-[80px]" : "self-end"
              }`}
            >
              {renderMap(formData.currentStep === 0)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Solar;
