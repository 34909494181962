import { TextField } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const LeadPurchase = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    // resolver: yupResolver(partnersRegisterFormSchema),
    defaultValues: {
      post_code: "",
    },
    mode: "onChange",
  });

  return (
    <div>
      <h1 className="text-[28px] font-semibold mb-4">Lead Purchase</h1>
      <div className="py-6 pr-4 ">
        <div className="">
          <h2 className="text-[#112532] font-bold">Calculate Leads Price</h2>
          <p className="mb-8 text-[#244255] text-sm font-normal">
            Fill the information below based on your reference, then choose
            number of leads you want to purchase
          </p>
        </div>
        <div className="flex justify-between">
          <div className="w-full flex flex-col gap-y-8">
            <Controller
              name="post_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.post_code}
                  helperText={errors.post_code && errors.post_code.message}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight" &&
                      e.key !== "Delete" &&
                      e.key !== "Tab"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  type="text"
                  id="outlined-basic"
                  label="Post Code"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadPurchase;
