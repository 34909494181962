import {
  Badge,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ConfirmationDialog from "components/shared/DeleteConfirmationDialog";
import { logout } from "hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GETadminPersonalDetails } from "services/AdminServices";
import {
  GETgeneralInformationHomeowner,
  PUTreadAllNotificationsHomeOwner,
} from "services/homeOwnerServices";
import {
  GETgeneralInformationInstaller,
  PUTreadAllNotificationsInstaller,
} from "services/installerServices";
import io from "socket.io-client";
import { setChatList, updateGeneralInformation } from "../../redux/actions";
import Notification from "./Notification";
import config from "../../config";

const AuthenticatedHeader = ({ isAdmin, chatListSearchQuery = "" }) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsExpanded, setIsNotificationsExpanded] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isNotificationRead, setIsNotificationRead] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [socket, setSocket] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const generalData = useSelector((state) => state.generalInformation);
  const chatList = useSelector((state) => state.chatList);

  const notificationRef = useRef(null);
  const profileMenuRef = useRef(null);

  const userRole = localStorage.getItem("USER");

  const fetchUserData = async () => {
    try {
      if (userRole === "homeOwner") {
        const res = await GETgeneralInformationHomeowner();
        dispatch(updateGeneralInformation(res));
      } else if (userRole === "installer") {
        const res = await GETgeneralInformationInstaller();
        dispatch(updateGeneralInformation(res));
      } else if (userRole === "admin") {
        const res = await GETadminPersonalDetails();
        dispatch(updateGeneralInformation(res.admin));
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [location]);

  const readAllNotifications = async () => {
    if (userRole === "installer") {
      try {
        const res = await PUTreadAllNotificationsInstaller();
        toast.success(res.message);
        setNotificationOpen(false);
        setIsNotificationRead(false);
      } catch (error) {
        toast.success(error.response.message);
      }
    } else if (userRole === "homeOwner") {
      try {
        const res = await PUTreadAllNotificationsHomeOwner();
        toast.success(res);
        setNotificationOpen(false);
        setIsNotificationRead(false);
      } catch (error) {
        toast.success(error.response.message);
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("USER");
    const socket = io(config.socketUrl, {
      query: { token, role },
      reconnection: true,
    });
    setSocket(socket);
    console.log(
      "updateChatList",
      generalData.id,
      userRole,
      chatListSearchQuery
    );
    socket.emit(
      "updateChatList",
      generalData.id,
      userRole,
      chatListSearchQuery
    );

    if (userRole === "installer") {
      socket.emit("installer_fetchNotifications");
    } else if (userRole === "homeOwner") {
      socket.emit("homeOwner_fetchNotifications");
    }

    socket.on("newNotification", (newNotification) => {
      setNotifications((prev) => [newNotification.newNotification, ...prev]);
      setIsNotificationRead(true);
    });

    socket.on("chatListUpdate", (chatList) => {
      dispatch(setChatList(chatList));
      console.log("Received chat list update:", chatList);
    });

    socket.on("notifyAll", (incomingNotifications) => {
      setNotifications(incomingNotifications);
    });

    return () => {
      socket.disconnect();
    };
  }, [userRole, notificationOpen, generalData.id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (socket) {
        socket.emit(
          "updateChatList",
          generalData.id,
          userRole,
          chatListSearchQuery
        );
      }
    }, 500);

    return () => clearTimeout(handler);
  }, [chatListSearchQuery]);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationOpen(false);
      }
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].isRead === false) {
        setIsNotificationRead(true);
        break;
      }
    }
  }, [notifications]);

  return (
    <div className="md:px-6 md:py-4">
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onConfirm={logout}
        cancelText="Cancel"
        confirmText="Log out"
        title="Log out of Spark?"
        subTitle="Are you sure you want to log out of Spark?"
      />
      <div
        className={`flex items-center justify-between bg-white px-4 md:px-8 py-2 md:py-4 shadow border border-[#ececee] ${isAdmin ? "md:rounded-t-[10px]" : "md:rounded-[10px]"}`}
      >
        <img src="/sparklogo.png" alt="Logo" fetchpriority="high" />
        <div className="flex items-center gap-x-3 md:gap-x-6">
          <div className="flex items-center gap-x-3">
            <div className="">
              <Badge
                variant="dot"
                invisible={!chatList?.badge}
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: "#FF5733", // Your custom color
                  },
                }}
              >
                <Link className="w-6" to="/messages">
                  <img
                    className="w-6 h-6"
                    src="/icons/mail.svg"
                    alt="Email Icon"
                  />
                </Link>
              </Badge>
            </div>
            <div ref={notificationRef}>
              <Badge
                variant="dot"
                invisible={!isNotificationRead}
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: "#FF5733", // Your custom color
                  },
                }}
              >
                <img
                  className="w-6 h-6 cursor-pointer"
                  src="/icons/notification.svg"
                  alt="Notification Icon"
                  onClick={() => setNotificationOpen((state) => !state)}
                />
              </Badge>
              {notificationOpen && (
                <div
                  className="flex flex-col gap-y-5 w-full md:w-[631px] h-screen md:h-[calc(100%-150px)] bg-white fixed md:absolute top-0 md:top-[100px] right-0 z-10 px-6 md:pb-6 overflow-y-scroll scrollbar-custom"
                  onTouchMove={(e) => e.preventDefault()} // Prevent touch scrolling
                >
                  <div className="flex justify-between items-center sticky top-0 bg-white z-20 py-4 pb-7 border-b border-[#C7CACE]">
                    <h1 className="text-[23px] font-bold flex gap-x-2">
                      <img
                        className="cursor-pointer"
                        src="/icons/arrow_back.svg"
                        alt=""
                        onClick={() => setNotificationOpen(false)}
                      />
                      Notifications
                    </h1>
                    {notifications.length > 0 && (
                      <h1
                        className="text-[#265D78] font-bold cursor-pointer"
                        onClick={readAllNotifications}
                      >
                        Read All
                      </h1>
                    )}
                  </div>

                  {notifications.length > 0 ? (
                    <>
                      {notifications
                        .slice(0, isNotificationsExpanded ? 50 : 5)
                        .map((notification, index) => (
                          <Notification
                            key={index}
                            notification={notification}
                          />
                        ))}
                      {notifications.length > 5 && (
                        <button
                          onClick={() =>
                            setIsNotificationsExpanded(!isNotificationsExpanded)
                          }
                          className=" pb-4 md:mt-4 text-[#F59E0B]"
                        >
                          {isNotificationsExpanded ? "Show Less" : "View More"}
                        </button>
                      )}
                    </>
                  ) : (
                    <div className="flex flex-col gap-y-4 justify-center items-center pt-16">
                      <img src="/images/no-notfications.png" alt="" />
                      <h1 className="text-[#244255] text-xl font-semibold">
                        No Notifications Yet!
                      </h1>
                      <p className="text-[#112532] text-sm">
                        There is no notifications to show here.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className={`relative flex items-center gap-x-2 ${profileMenuOpen ? "bg-[#F7F6F5]" : ""}`}
            ref={profileMenuRef}
          >
            <img
              className="w-10 h-10 rounded-full cursor-pointer"
              src={generalData?.profile_img}
              alt="Profile"
              onClick={() => setProfileMenuOpen((prev) => !prev)}
            />
            <h1
              className="text-[#112532] font-semibold cursor-pointer hidden md:block"
              onClick={() => setProfileMenuOpen((prev) => !prev)}
            >
              {generalData.fullName}
            </h1>

            {/* Profile dropdown on click */}
            {profileMenuOpen && (
              <div className="absolute w-[168px] right-0 md:left-1/2 top-[40px] mt-2 md:translate-x-[-50%] bg-white shadow-lg z-50 rounded-b-md">
                <List component="nav" aria-label="main mailbox folders">
                  <ListItemButton
                    className="block md:hidden"
                    sx={{
                      padding: "12px 16px", // Custom padding
                    }}
                  >
                    <ListItemText primary={generalData.fullName} />
                  </ListItemButton>
                  <hr className="bg-[#D9D3CF]" />
                  <Link to={"/personal-details"}>
                    <ListItemButton
                      sx={{
                        padding: "12px 16px", // Custom padding
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        <img src="/icons/account_circle.svg" alt="" />
                      </ListItemIcon>
                      <ListItemText primary="My Profile" />
                    </ListItemButton>
                  </Link>
                  <hr className="bg-[#D9D3CF]" />
                  <ListItemButton
                    onClick={() => {
                      setIsDialogOpen(true);
                    }}
                    sx={{
                      padding: "12px 16px", // Custom padding
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <img src="/icons/line-md_logout.svg" alt="" />
                    </ListItemIcon>
                    <ListItemText primary="Log out" />
                  </ListItemButton>
                </List>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedHeader;
