import ChangePassword from "components/shared/ChangePassword";
import Chat from "components/shared/Chat";
import ContactUs from "components/shared/ContactUs";
import SignContract from "components/shared/Contract";
import DealOverview from "components/shared/DealOverview";
import AddImagePortfolio from "components/installer/AddImagePortfolio";
import MyDeals from "components/installer/MyDeals";
import InstallerBusinessDetails from "components/shared/InstallerBusinessDetails";
import InstallerPersonalDetails from "components/shared/InstallerPersonalDetails";
import ProfilePage from "components/shared/ProfilePage";
import HomeLayout from "layout/HomeLayout";
import About from "pages/About";
import ForgetPassword from "pages/auth/ForgetPassword";
import InstallerLogin from "pages/auth/InstallerLogin";
import Login from "pages/auth/Login";
import MainAuthGuard from "pages/auth/MainAuthGuard";
import ResetPassword from "pages/auth/ResetPassword";
import Messages from "pages/logged-in/Messages";
import WhyGoSolar from "pages/WhyGoSolar";
import { createBrowserRouter } from "react-router-dom";

import DashboardInstaller from "./components/installer/Dashboard";
import EquipmentList from "./components/installer/EquipmentList";
import InstallerProfileShared from "./components/shared/InstallerProfileShared";
import DashboardLayoutInstaller from "./pages/logged-in/DashboardInstaller";
import SiteSurvey from "./pages/logged-in/SiteSurvey";
import Solar from "./pages/logged-in/Solar";
import NotFound from "./pages/Page404";

import ReferFriend from "components/home-owner/ReferFriend";
import InstallerRegister from "pages/auth/InstallerRegister";
import Home from "pages/Home";
import Profile from "pages/logged-in/Profile";
import HomeOwnerDashboard from "components/home-owner/HomeOwnerDashboard";
import AdminDashboardLayout from "components/Admin/AdminDashboardLayout";
import AdminDashboard from "components/Admin/AdminDashboard";
import AllInstallersTable from "components/Admin/AllInstallersTable";
import AllHomeownersTable from "components/Admin/AllHomeownersTable";
import AdminLogin from "pages/auth/AdminLogin";
import InstallerConfig from "components/Admin/InstallerConfig";
import HomeownerDeal from "components/Admin/HomeownerDeal";
import InstallerProfile from "./components/Admin/InstallerProfile";
import PartnersRegister from "pages/auth/PartnersRegister";
import PartnersLogin from "pages/auth/PartnersLogin";
import LeadPurchase from "components/installer/LeadPurchase";

export const router = createBrowserRouter(
  [
    {
      path: "auth",
      children: [
        {
          path: "admin-login",
          element: <AdminLogin />,
        },
        {
          path: "partners-login",
          element: <PartnersLogin />,
        },
        {
          path: "partners-registration",
          element: <PartnersRegister />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "installer-login",
          element: <InstallerLogin />,
        },
        {
          path: "register",
          element: <Solar />,
        },
        {
          path: "installer-register",
          element: <InstallerRegister />,
        },
        {
          path: "forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "installer-forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "change-password/:resetToken",
          element: <ResetPassword />,
        },
        {
          path: "installer-change-password/:resetToken",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "messages",
      element: (
        <MainAuthGuard>
          <Messages />
        </MainAuthGuard>
      ),
      children: [
        {
          path: "messages/:userId",
          element: <Chat />,
        },
      ],
    },
    {
      path: "personal-details",
      element: (
        <MainAuthGuard>
          <Profile />
        </MainAuthGuard>
      ),
      children: [
        {
          index: true,
          element: <InstallerPersonalDetails />,
        },
        { path: "business-details", element: <InstallerBusinessDetails /> },
        { path: "change-password", element: <ChangePassword /> },
      ],
    },
    {
      path: "admin-dashboard",
      element: <AdminDashboardLayout />,
      children: [
        {
          index: true,
          element: <AdminDashboard />,
        },
        {
          path: "installers",
          element: <AllInstallersTable />,
        },
        {
          path: "installers/:userId",
          element: <InstallerConfig />,
        },
        {
          path: "homeowners",
          element: <AllHomeownersTable />,
        },
        {
          path: "homeowners/:userId",
          element: <HomeownerDeal />,
        },
        {
          path: "homeowners/installer/:userId",
          element: <InstallerProfile />,
        },
      ],
    },
    {
      path: "partners",
      element: <AdminDashboardLayout />,
      children: [
        {
          index: true,
          element: <AdminDashboard />,
        },
        {
          path: "installers",
          element: <AllInstallersTable />,
        },
        {
          path: "installers/:userId",
          element: <InstallerConfig />,
        },
        {
          path: "homeowners",
          element: <AllHomeownersTable />,
        },
        {
          path: "homeowners/:userId",
          element: <HomeownerDeal />,
        },
      ],
    },
    {
      path: "dashboard",
      children: [
        {
          index: true,
          element: (
            <MainAuthGuard>
              <HomeOwnerDashboard />
            </MainAuthGuard>
          ),
        },
        {
          path: "site-survey",
          element: <SiteSurvey />,
        },
        { path: "contract", element: <SignContract /> },
        { path: "installer-profile", element: <InstallerProfileShared /> },
        { path: "deal-overview", element: <DealOverview /> },
        { path: "refer-friend", element: <ReferFriend /> },
      ],
    },
    {
      path: "/dashboard-installer",
      element: (
        <MainAuthGuard>
          <DashboardLayoutInstaller />
        </MainAuthGuard>
      ),
      children: [
        {
          index: true,
          element: <DashboardInstaller />,
        },
        {
          path: "equipmentlist",
          element: <EquipmentList />,
        },
        {
          path: "mydeals",
          element: <MyDeals />,
        },
        {
          path: "mydeals/:userId",
          element: <DealOverview />,
        },
        { path: "mydeals/:userId/contract", element: <SignContract /> },
        {
          path: "portfolio",
          element: <ProfilePage />,
          children: [
            {
              index: true,
              element: <InstallerProfileShared />,
            },
            {
              path: "add-image",
              element: <AddImagePortfolio />,
            },
          ],
        },
        {
          path: "lead-purchase",
          element: <LeadPurchase />,
        },
      ],
    },
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "why-go-solar",
          element: <WhyGoSolar />,
        },
        {
          path: "get-started",
          element: <Solar />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
  {
    future: {
      v7_partialHydration: true,
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);
