import { object, ref, string } from "yup";

export const partnersRegisterFormSchema = object().shape({
  first_name: string()
    .required("First Name is required")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed.")
    .default(""),
  last_name: string()
    .required("Last Name is required")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed.")
    .default(""),
  email: string()
    .required("Email is required!")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    )
    .default(""),
  password: string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .default(""),
  confirmPassword: string()
    .required("Please confirm your password")
    .oneOf([ref("password"), null], "Passwords must match")
    .default(""),
  business_name: string()
    .required("Business name is required!")
    .matches(/^[A-Za-z\s]+$/, "Symbols, numbers, are not allowed.")
    .default(""),
  mobile: string()
    .required("Phone number is required!")
    .length(10, "Phone number must be exactly 10 digits")
    .default(""),
  country: string()
    .required("Country is required!")
    .default("United States of America (USA)"),
  state: string().required("State is required!").default(""),
  city: string()
    .required("City is required!")
    .matches(/^[A-Za-z]+$/, "Symbols, numbers, are not allowed.")
    .default(""),
  post_code: string().required("Post code is required!").default(""),
});
