import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import {
  GETaverageSystemSizeStatistics,
  GETcontractLeadsStatistics,
  GETwinRateStatistics,
} from "services/installerServices";
import { CustomizeTabs } from "components/MUI/CustomizeTabs";
import { useOutletContext } from "react-router-dom";
import useResponsive from "hooks/useResponsive";
import { formatPrice } from "utils/formatNumber";

const Dashboard = () => {
  const dashboardSidebar = useOutletContext();
  const [winRateData, setWinRateData] = useState([]);
  const [contractAndLeadData, setContractAndLeadData] = useState([]);
  const [averageSystemSizeData, setAverageSystemSizeData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [filterBy, setFilterBy] = useState("oneMonth");

  const areAllObjectsEmpty = (array, field = null) => {
    if (!array?.length) return true;

    return array.every((item) => {
      if (field) {
        if (typeof item[field] === "string") {
          return parseFloat(item[field]) === 0;
        }
        return item[field] === 0;
      }

      return Object.entries(item).every(([key, value]) => {
        if (key === "group") return true;

        if (typeof value === "string") {
          return parseFloat(value) === 0;
        }
        return value === 0;
      });
    });
  };

  const { isSm } = useResponsive();

  const currentDate = dayjs().format("MMM DD");
  const getPreviousMonthName = (num) => {
    // Subtract `num` months from the current date
    const previousMonth = dayjs().subtract(num, "month").format("MMM DD");
    return previousMonth;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async () => {
    try {
      const winRateRes = await GETwinRateStatistics();
      const contractAndLeadRes = await GETcontractLeadsStatistics();
      const averageSystemSizeRes = await GETaverageSystemSizeStatistics();
      setWinRateData(winRateRes);
      setContractAndLeadData(contractAndLeadRes);
      setAverageSystemSizeData(averageSystemSizeRes);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Common chart configurations
  const getCommonXAxisConfig = (dataKey, dateFormat) => ({
    interval: 0,
    dataKey,
    tickLine: false,
    axisLine: false,
    tick: {
      fill: "#1C1C1C66",
      fontSize: isSm ? 10 : 12,
      textAnchor: "middle",
      angle: -45,
      dx: isSm ? -10 : 0,
      dy: isSm ? 10 : 5,
    },
    tickFormatter: (tick) =>
      dayjs(tick).format(dateFormat === "full" ? "MMM D" : "MMM"),
  });

  const commonYAxisConfig = {
    axisLine: false,
    tickLine: false,
    tick: {
      fill: "#1C1C1C66",
      fontSize: isSm ? 10 : 12,
      dy: -3,
    },
    tickMargin: 16,
  };

  const chartMargin = {
    left: 0,
    right: 20,
    top: 10,
    bottom: isSm ? 40 : 20,
  };

  const tabs = [
    {
      label: "1 Month",
      value: 0,
      sx: { color: "#fff", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "First tab",
      onClick: () => setFilterBy("oneMonth"),
    },
    {
      label: "3 Months",
      value: 1,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Second tab",
      onClick: () => setFilterBy("threeMonths"),
    },
    {
      label: "6 Months",
      value: 2,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Third tab",
      onClick: () => setFilterBy("sixMonths"),
    },
    {
      label: "1 Year",
      value: 3,
      sx: { color: "#000", fontSize: isSm ? 14 : 16, minWidth: "20%" },
      disableRipple: true,
      ariaLabel: "Fourth tab",
      onClick: () => setFilterBy("oneYear"),
    },
  ];

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (!active || !payload || !payload.length) return null;
  //   console.log(payload);

  //   return (
  //     <div className="bg-white  text-sm p-3 rounded-lg shadow-lg border border-gray-700">
  //       {/* Tooltip Header */}
  //       <div className="font-semibold border-b border-gray-700 pb-1 mb-2">{`Date: ${payload[0].payload.start_of_week} To ${payload[0].payload.end_of_week}`}</div>

  //       {/* Tooltip Body */}
  //       <div className="space-y-1">
  //         {payload.map((entry, index) => (
  //           <div key={index} className="flex justify-between">
  //             <span className="capitalize">
  //               {entry.name.replace(/_/g, " ")}
  //             </span>
  //             <span style={{ color: entry.color }} className="font-medium">
  //               {entry.value}%
  //             </span>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="flex flex-col gap-y-8 px-4 md:px-8 flex-1 pb-6">
      {/* Header Section */}
      <div className="flex items-center gap-x-2 p-6 pl-0">
        <div className="lg:hidden">{dashboardSidebar}</div>
        <h1 className="text-[23px] md:text-[28px] font-semibold">Dashboard</h1>
      </div>

      {/* Tabs Section */}
      <div className="w-full">
        <CustomizeTabs
          value={tabValue}
          onChange={handleTabChange}
          tabs={tabs}
          tabsWidth="100%"
        />
      </div>

      {/* Charts Section */}
      <div className="flex flex-col items-center gap-y-6 w-full">
        {/* Win Rate Chart */}
        <div className="p-4 md:p-6 border border-[#ECEAE8] bg-[#FFFEFB] w-full rounded-lg">
          <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
            Win Rate
          </h1>

          <div className="h-[300px] w-full">
            {areAllObjectsEmpty(winRateData[filterBy], "win_rate") ? (
              <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                <img src="/images/empty-win-rate.png" alt="" />
                <p className="text-[#112532] text-sm">No win rate</p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={winRateData[filterBy]} margin={chartMargin}>
                  <CartesianGrid vertical={false} />
                  <CartesianGrid horizontal={false} strokeDasharray="3 3" />
                  <XAxis
                    {...getCommonXAxisConfig(
                      (s) =>
                        filterBy === "oneMonth" || filterBy === "threeMonths"
                          ? s.group.start_of_week
                          : s.group.month,
                      "full"
                    )}
                  />
                  <YAxis
                    {...commonYAxisConfig}
                    tickFormatter={(tick) => (tick !== 0 ? `${tick}%` : tick)}
                  />
                  <Tooltip
                    formatter={(value, name) => [
                      value + "%",
                      ,
                      name
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase()),
                    ]}
                  />
                  <Line
                    type="monotone"
                    dataKey="win_rate"
                    stroke="#F59E0B"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        {/* Bottom Charts Container */}
        <div className="flex flex-col lg:flex-row gap-6 w-full">
          {/* My Deals Chart */}
          <div className="border border-[#ECEAE8] bg-[#FFFEFB] w-full p-4 md:p-5 rounded-lg">
            <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
              My Deals
            </h1>

            <div className="h-[300px] w-full ">
              {areAllObjectsEmpty(contractAndLeadData[filterBy], "contracts") &&
              areAllObjectsEmpty(contractAndLeadData[filterBy], "leads") ? (
                <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                  <img src="/images/empty-bar-chart.png" alt="" />
                  <p className="text-[#112532] text-sm">No deals</p>
                </div>
              ) : (
                <>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={contractAndLeadData[filterBy]}
                      margin={chartMargin}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis
                        {...getCommonXAxisConfig(
                          (s) =>
                            filterBy === "oneMonth" ||
                            filterBy === "threeMonths"
                              ? s.group.start_of_week
                              : s.group.month,
                          "full"
                        )}
                      />
                      <YAxis {...commonYAxisConfig} />
                      <Tooltip cursor={{ fill: "#fdf0da" }} />
                      <Bar
                        dataKey="leads"
                        fill="#F59E0B"
                        barSize={isSm ? 20 : 30}
                      />
                      <Bar
                        dataKey="contracts"
                        fill="#244255"
                        barSize={isSm ? 30 : 45}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  <div className="flex justify-center gap-x-5 ">
                    <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
                      <span className="w-[14px] h-[7px] bg-[#F59E0B]" /> Leads
                    </p>
                    <p className="text-[9px] md:text-[11px] text-[#112532] font-semibold flex items-center gap-x-1">
                      <span className="w-[14px] h-[7px] bg-[#244255]" />{" "}
                      Contracts
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Average System Size Chart */}
          <div className="border border-[#ECEAE8] bg-[#FFFEFB] w-full p-4 md:p-5 rounded-lg">
            <h1 className="mb-4 text-[#112532] text-[18px] font-semibold">
              Average System Size
            </h1>

            <div className="h-[300px] w-full">
              {areAllObjectsEmpty(
                averageSystemSizeData[filterBy],
                "average_system_size"
              ) ? (
                <div className="h-full flex flex-col gap-y-4 items-center justify-center">
                  <img src="/images/empty-bar-chart.png" alt="" />
                  <p className="text-[#112532] text-sm">
                    No average system size
                  </p>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={averageSystemSizeData[filterBy]}
                    margin={chartMargin}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      {...getCommonXAxisConfig(
                        (s) =>
                          filterBy === "oneMonth" || filterBy === "threeMonths"
                            ? s.group.start_of_week
                            : s.group.month,
                        "full"
                      )}
                    />
                    <YAxis
                      {...commonYAxisConfig}
                      tickFormatter={(tick) =>
                        tick !== 0 ? `${tick}kW` : tick
                      }
                    />
                    <Tooltip
                      cursor={{ fill: "#fdf0da" }}
                      formatter={(value, name) => [
                        formatPrice(value) + "kW",
                        name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase()),
                      ]}
                    />
                    <Bar
                      dataKey="average_system_size"
                      fill="#F59E0B"
                      barSize={isSm ? 30 : 45}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
